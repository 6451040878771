import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardTwoToneIcon from "@mui/icons-material/DashboardTwoTone";
import GroupTwoToneIcon from "@mui/icons-material/GroupTwoTone";
import SellTwoToneIcon from "@mui/icons-material/SellTwoTone";
import { LogoutTwoTone } from "@mui/icons-material";
import ReceiptLongTwoToneIcon from "@mui/icons-material/ReceiptLongTwoTone";
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import FoodBankTwoToneIcon from '@mui/icons-material/FoodBankTwoTone';
import CelebrationTwoToneIcon from '@mui/icons-material/CelebrationTwoTone';
import logo from "../static/images/scale logo.svg";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

export default function Layout({ title, body }) {
  const navigate = useNavigate();
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar>
          <img alt="Scale Kuwait" src={logo} height="25" />
          <Typography variant="h5" sx={{ ml: 2 }}>
            Scale Admin
          </Typography>
        </Toolbar>
        <Divider />
        <List>
          {/* <Link to='/dashboard' > */}
          <ListItem
            button
            key={"Dashboard"}
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            <ListItemIcon>
              <DashboardTwoToneIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={"Dashboard"} />
          </ListItem>
          {/* </Link> */}

          <ListItem
            button
            key={"Plans"}
            onClick={() => {
              navigate("/plans");
            }}
          >
            <ListItemIcon>
              <GroupTwoToneIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={"plans"} />
          </ListItem>

          

          <ListItem
            button
            key={"Buy Subscription"}
            onClick={() => {
              navigate("/buy-subscriptions");
            }}
          >
            <ListItemIcon>
              <ShoppingCartTwoToneIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={"Buy Subscription"} />
          </ListItem>

          <ListItem
            button
            key={"Order Lists"}
            onClick={() => {
              navigate("/order-list-page");
            }}
          >
            <ListItemIcon>
              <ReceiptLongTwoToneIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={"Order Lists"} />
          </ListItem>
          <ListItem
            button
            key={"Meals"}
            onClick={() => {
              navigate("/meals-page");
            }}
          >
            <ListItemIcon>
              <FoodBankTwoToneIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={"Meals"} />
          </ListItem>
          <ListItem
            button
            key={"Coupons"}
            onClick={() => {
              navigate("/coupon-list");
            }}
          >
            <ListItemIcon>
              <SellTwoToneIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={"Coupons"} />
          </ListItem>
          <ListItem
            button
            key={"Events"}
            onClick={() => {
              navigate("/events");
            }}
          >
            <ListItemIcon>
              <CelebrationTwoToneIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={"Events"} />
          </ListItem>
          
          <ListItem
            button
            key={"Logout"}
            onClick={() => {
              sessionStorage.setItem("isAuthenticated", "false");
              sessionStorage.removeItem("token");
              navigate("/");
            }}
            sx={{ mt: 5 }}
          >

            <ListItemIcon>
              <LogoutTwoTone color="error" />
            </ListItemIcon>
            <ListItemText sx={{ color: "red" }} primary={"Logout"} />
          </ListItem>

          {/* <ListItem
            button
            key={"Meh"}
            onClick={() => {
              navigate("/meh");
            }}
          >
            <ListItemIcon>
              <GroupTwoToneIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={"Meh"} />
          </ListItem> */}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
      >
        <Toolbar />
        {body}
      </Box>
    </Box>
  );
}
// function ListItemLink(props) {
//   const { icon, primary, to } = props;

//   const renderLink = React.useMemo(
//     () =>
//       React.forwardRef(function Link(itemProps, ref) {

//         return <Link to={to} ref={ref} {...itemProps} role={undefined} />;
//       }),
//     [to],
//   );

//   return (
//     <li>
//       <ListItem button component={renderLink}>
//         {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
//         <ListItemText primary={primary} />
//       </ListItem>
//     </li>
//   );
// }

// ListItemLink.propTypes = {
//   icon: PropTypes.element,
//   primary: PropTypes.string.isRequired,
//   to: PropTypes.string.isRequired,
// };
