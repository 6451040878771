import "./App.css";
import React from "react";
import LoginPage from "./pages/LoginPage";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import ProtectedPage from "./pages/ProtectedPage";
import DashboardPage from "./pages/dashboard/dashboardPage";
import PlansPage from "./pages/plans/plansPage";
import CreatePlanPage from "./pages/plans/createPlan"; // Add this line to import CreatePlanPage
import SubscriptionsPlansListPage from "./pages/subscription/suscriptionsPlansListPage";
import BuySubscription from "./pages/subscription/buySubscriptionPage";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#649230",
    },
    secondary: {
      main: "#8B8B8A",
    },
    // background: {
    //   default: "#161615",
    //   paper: "#2D2D2C",
    // },
  },
  // typography: {
  //   fontfamily: "Nunito",
  //   fontWeightLight: 300,
  //   fontWeightRegular: 400,
  //   fontWeightMedium: 600,
  //   fontWeightBold: 700,
  // },
});

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="" element={<LoginPage />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedPage>
                <DashboardPage />
              </ProtectedPage>
            }
          />
          <Route
            path="/plans"
            element={
              <ProtectedPage>
                <PlansPage />
              </ProtectedPage>
            }
          />
          <Route
            path="/plans/create"
            element={
              <ProtectedPage>
                <CreatePlanPage />
              </ProtectedPage>
            }
          />
          <Route
            path="/buy-subscription-plans-list"
            element={
              <ProtectedPage>
                <SubscriptionsPlansListPage />
              </ProtectedPage>
            }
          />
          <Route
            path="/buy-subscriptions"
            element={
              <ProtectedPage>
                <BuySubscription />
              </ProtectedPage>
            }
          />

        </Routes>
      </ThemeProvider>
    </Router>
  );
}

export default App;
