import axios from 'axios';

// Create an instance of axios
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor to the instance
axiosInstance.interceptors.request.use(function (config) {
  // Retrieve the token from localStorage
  const token = sessionStorage.getItem('token');
  // If the token exists, add it to the request's Authorization header
  if (token) {
    config.headers['Authorization'] = token;
  }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

export default axiosInstance;