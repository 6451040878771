import React, { useEffect, useState } from "react";
import axiosInstance from "../../api/axios";
import { Link, Typography, Box, CircularProgress, Button } from "@mui/material";
import Layout from "../../layout/layout";
import { useNavigate } from 'react-router-dom';

function PlansPage() {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchPlans = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get("/plans/");
        setPlans(response.data);
        console.log(plans);
      } catch (error) {
        console.error("Failed to fetch plans", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, []);

  const navigate = useNavigate();

  const body = <Box sx={{ p: 2 }}>
    <Typography variant="h4">Plans</Typography>
    <Button
      variant="contained"
      color="primary"
      style={{ marginBottom: '20px' }}
      onClick={() => navigate('/plans/create')} // Use navigate to change the route
    >
      Create Plan
    </Button>
    {loading ? (
      <CircularProgress />
    ) : (
      <Box>
        {plans.map((plan) => (
          <Box key={plan.id} sx={{ mb: 2 }}>
            <Link href={`/plan/${plan.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
              <Typography variant="h6">{plan.name}</Typography>
              <Typography variant="body1">{plan.description}</Typography>
            </Link>
          </Box>
        ))}
      </Box>
    )}
  </Box>;
  return (
    <Layout title="Plans" body={body}>
      
    </Layout>
  );
}

export default PlansPage;