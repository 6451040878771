import React, { useState } from 'react';
import axiosInstance from '../../api/axios';
import { Button, TextField, Box, Typography, IconButton } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Layout from '../../layout/layout';

function CreatePlanPage() {
	const [formData, setFormData] = useState({
		name: '',
		description: '',
		duration_options: '',
		box_price: '',
		price: '',
		dish_categories: [{ name: '', description: '', price: '', is_carb_changeable: false, is_fixed_menu: false, dish_limit: '', dish_limit_min: '', combos: [{ carb_amount: '', protein_amount: '', other_field: '' }] }]
	});

	const handleChange = (event) => {
	  const { name, value } = event.target;
	  setFormData(prevFormData => ({
		...prevFormData,
		[name]: value,
	  }));
	};
	
	const handleDurationChange = (event) => {
	  const { name, value } = event.target;
	
	  if (name === "duration_options") {
		// Assuming the input is a comma-separated list of numbers
		const numberArray = value.split(',').map(num => parseInt(num.trim(), 10)).filter(num => !isNaN(num));
		setFormData(prevFormData => ({
		  ...prevFormData,
		  [name]: numberArray,
		}));
	  } else {
		setFormData(prevFormData => ({
		  ...prevFormData,
		  [name]: value,
		}));
	  }
	};

	


	const handleDCandComboChange = (e, index, comboIndex = null) => {
		if (comboIndex === null) {
			const { name, value } = e.target;
			if (name.startsWith('dish_categories')) {
				const fieldName = name.split('.')[2];
				const updatedCategories = formData.dish_categories.map((category, i) => {
					if (i === index) {
						return { ...category, [fieldName]: value };
					}
					return category;
				});
				setFormData({ ...formData, dish_categories: updatedCategories });
			} else {
				setFormData({ ...formData, [name]: value });
			}
		} else {
			const { name, value } = e.target;
			const fieldName = name.split('.')[3];
			const updatedCategories = formData.dish_categories.map((category, i) => {
				if (i === index) {
					const updatedCombos = category.combos.map((combo, j) => {
						if (j === comboIndex) {
							return { ...combo, [fieldName]: value };
						}
						return combo;
					});
					return { ...category, combos: updatedCombos };
				}
				return category;
			});
			setFormData({ ...formData, dish_categories: updatedCategories });
		}
	};

	const addDishCategory = () => {
		setFormData({
			...formData,
			dish_categories: [...formData.dish_categories, { name: '', description: '', price: '', is_carb_changeable: false, is_fixed_menu: false, dish_limit: '', dish_limit_min: '', combos: [{ carb_amount: '', protein_amount: '', other_field: '' }] }]
		});
	};

	const removeDishCategory = (index) => {
		const updatedCategories = formData.dish_categories.filter((_, i) => i !== index);
		setFormData({ ...formData, dish_categories: updatedCategories });
	};

	const addCombo = (categoryIndex) => {
		const updatedCategories = formData.dish_categories.map((category, i) => {
			if (i === categoryIndex) {
				return { ...category, combos: [...category.combos, { carb_amount: '', protein_amount: '', other_field: '' }] };
			}
			return category;
		});
		setFormData({ ...formData, dish_categories: updatedCategories });
	};

	const removeCombo = (categoryIndex, comboIndex) => {
		const updatedCategories = formData.dish_categories.map((category, i) => {
			if (i === categoryIndex) {
				const updatedCombos = category.combos.filter((_, j) => j !== comboIndex);
				return { ...category, combos: updatedCombos };
			}
			return category;
		});
		setFormData({ ...formData, dish_categories: updatedCategories });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await axiosInstance.put('/plans/create/', {
				...formData,
				duration_options: formData.duration_options.split(',').map(Number) // Assuming duration_options is a comma-separated string
			});
			console.log(response.data);
			// Handle success (e.g., showing a success message or redirecting)
		} catch (error) {
			console.error('Failed to create plan', error);
			// Handle error (e.g., showing an error message)
		}
	};

	const body = <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
		<Typography variant="h6">Create Plan</Typography>
		{/* Existing TextFields for name, description, etc. */}
		<TextField
		  margin="normal"
		  required
		  fullWidth
		  id="name"
		  label="Plan Name"
		  name="name"
		  autoComplete="name"
		  autoFocus
		  value={formData.name}
		  onChange={handleChange}
		/>
		<TextField
		  margin="normal"
		  required
		  fullWidth
		  name="price"
		  label="Price"
		  type="number"
		  id="price"
		  autoComplete="price"
		  value={formData.price}
		  onChange={handleChange}
		/>
		<TextField
		  margin="normal"
		  required
		  fullWidth
		  name="description"
		  label="Description"
		  type="text"
		  id="description"
		  multiline
		  rows={4}
		  autoComplete="description"
			value={formData.description}
			onChange={handleChange}
		/>
		<TextField
			margin="normal"
			fullWidth
			name="duration_options"
			label="Duration Options"
			type="text"
			id="duration_options"
			autoComplete="duration_options"
			value={formData.duration_options}
			onChange={handleDurationChange}
			placeholder="e.g., 1, 2, 3"
		/>
		{/* Add additional fields here */}
		{/* Add UI for dish_categories and combos here */}
		{formData.dish_categories.map((category, index) => (
			<Box key={index}>
				<Typography variant="h6">Dish Category {index + 1}</Typography>
				<TextField
					margin="normal"
					required
					fullWidth
					label="Name"
					name={`dish_categories.${index}.name`}
					value={category.name}
					onChange={(e) => handleDCandComboChange(e, index)}
				/>
				{/* Additional TextFields for each property of dish_categories */}
				{category.combos.map((combo, comboIndex) => (
					<Box key={comboIndex}>
						<Typography>Combo {comboIndex + 1}</Typography>
						<TextField
							margin="normal"
							required
							fullWidth
							label="Carb Amount"
							name={`dish_categories.${index}.combos.${comboIndex}.carb_amount`}
							value={combo.carb_amount}
							onChange={(e) => handleDCandComboChange(e, index, comboIndex)}
						/>
						{/* Additional TextFields for each property of combos */}
					</Box>
				))}
				<IconButton onClick={() => addCombo(index)}><AddCircleOutlineIcon /></IconButton>
				<IconButton onClick={() => removeCombo(index, category.combos.length - 1)}><RemoveCircleOutlineIcon /></IconButton>
			</Box>
		))}
		<IconButton onClick={addDishCategory}><AddCircleOutlineIcon /></IconButton>
		<IconButton onClick={() => removeDishCategory(formData.dish_categories.length - 1)}><RemoveCircleOutlineIcon /></IconButton>
		<Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
			Create Plan
		</Button>
	</Box>;

	return (<Layout title="Create Plan" body={body} />);
}

export default CreatePlanPage;