import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const ProtectedPage = ({ children }) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (sessionStorage.getItem("isAuthenticated") !== "true" || !sessionStorage.getItem("token")) {
            navigate("/");
        }
    }, [navigate]);

    return children;
};

export default ProtectedPage;
