/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axiosInstance from "../../api/axios";
import { Link, Typography, Box, CircularProgress } from "@mui/material";
import Layout from "../../layout/layout";

function SubscriptionsPlansListPage() {
    const [plans, setPlans] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchPlans = async () => {
            setLoading(true);
            try {
                const response = await axiosInstance.get("/plans/");
                setPlans(response.data);
                console.log(plans);
            } catch (error) {
                console.error("Failed to fetch plans", error);
            } finally {
                setLoading(false);
            }
        };

        fetchPlans();
    }, []);


    const body = <Box sx={{ p: 2 }}>
        
        {loading ? (
            <CircularProgress />
        ) : (
            <Box>
                {plans.map((plan, index) => (
                    <Box
                        key={plan.id}
                        sx={{
                            mb: 2,
                            border: 1,
                            borderColor: 'divider',
                            borderRadius: '4px',
                            ...(index < plans.length - 1 ? { mb: 2 } : {}), // Add margin-bottom to all but the last item
                        }}
                    >
                        <Link to={{ pathname: `/buy-subscription`, state: { plan: plan } }} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Box sx={{ cursor: 'pointer', padding: 2 }}> {/* Added padding for better spacing */}
                                <Typography variant="h6">{plan.name}</Typography>
                                <Typography variant="body1">{plan.description}</Typography>
                            </Box>
                        </Link>
                    </Box>
                ))}
            </Box>
        )}
    </Box>;
    return (
        <Layout title="Plans" body={body}>

        </Layout>
    );
}

export default SubscriptionsPlansListPage;