import React, { useEffect, useState } from "react";
import axiosInstance from "../../api/axios"; // Update the path to your axiosInstance
import Layout from "../../layout/layout";
import { Typography, Box, CircularProgress } from "@mui/material";

function DashboardPage() {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchStats = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get("stats/");
        setStats(response.data);
      } catch (error) {
        console.error("Failed to fetch stats", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);
  const body = (
    <Box sx={{ p: 2 }}>
      {loading ? (
        <CircularProgress />
      ) : stats ? (
        <Typography variant="h6">Total Plans: {stats.total_plans}</Typography>
      ) : (
        <Typography variant="body1">Failed to load stats</Typography>
      )}
    </Box>
  );

  return (
    <Layout title="Dashboard" body={body} >
      
    </Layout>
  );
}

export default DashboardPage;