/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Layout from "../../layout/layout";
import { Box, Typography, MenuItem, FormControl, InputLabel, Select, ToggleButtonGroup, ToggleButton, Grid, TextField, ListItemButton, ListItemText, List } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import axiosInstance from "../../api/axios";
import { pl } from "date-fns/locale";

function BuySubscription() {
    const [plans, setPlans] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState('');
    const [plan, setPlan] = useState(null);
    const [selectedDuration, setSelectedDuration] = useState(plan && plan.duration_options.length > 0 ? plan.duration_options[0].toString() : '');

    // Update selectedDuration if plan changes
    useEffect(() => {
        if (plan && plan.duration_options.length > 0) {
            setSelectedDuration(plan.duration_options[0].toString());
        }
    }, [plan]);


    useEffect(() => {
        const fetchPlans = async () => {
            setLoading(true);
            try {
                const response = await axiosInstance.get("/plans/");
                setPlans(response.data);
                console.log(plans);
            } catch (error) {
                console.error("Failed to fetch plans", error);
            } finally {
                setLoading(false);
            }
        };

        fetchPlans();
    }, []);



    const handlePlanChange = (event) => {
        setSelectedPlan(event.target.value);
        setPlan(plans.find((plan) => plan.id === event.target.value));
    };

    const showPlanName = plan ? (<><p>{plan.name}</p> <p> { plan.description}</p></>) : (<p>No Plan Selected</p>);

    const showPlans = (<FormControl fullWidth >
        <InputLabel id="plan-select-label">Plan</InputLabel>
        <Select
            labelId="plan-select-label"
            id="plan-select"
            value={selectedPlan}
            label="Plan"
            onChange={handlePlanChange}
        >
            <MenuItem value="">
                <em>None</em>
            </MenuItem>
            {plans.map((plan) => (
                <MenuItem key={plan.id} value={plan.id}>
                    {plan.name}
                </MenuItem>
            ))}
        </Select>
    </FormControl>);




    const handleDurationChange = (event, newDuration) => {
        setSelectedDuration(newDuration);
    };

    const showDurations = plan ? (
        <ToggleButtonGroup
            color="primary"
            value={selectedDuration.toString()} // Ensure this matches the value prop of the ToggleButtons
            exclusive
            onChange={handleDurationChange}
            aria-label="Duration"
            sx={{ mt: 2 }}
        >
            {plan.duration_options.map((duration, index) => (
                <ToggleButton key={index} value={duration.toString()} aria-label={`${duration} days`}>
                    {duration} days
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    ) : null;

    const showDishCategories = plan ? (
        plan.dish_categories.map((category) => (<><div key={category.id}>{category.name}</div>
            <div>{category.description}</div>
        </>
            
        ))
    ) : null;



    // find user by phone number
    const [userPhoneNumber, setUserPhoneNumber] = useState('');
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isValidID, setIsValidID] = useState(true);
    const getUsernameFromPhoneNumber = async (userPhoneNumber) => {
        if (userPhoneNumber === '') {
            setUsers([]);
            setSelectedUser(null);
            setIsValidID(false);
            return;
        }
        try {
            const response = await axiosInstance.post(`/find-users-by-phone-number/`, { phone_number: userPhoneNumber });
            setUsers(response.data);
            setIsValidID(true);
            console.log(response.data);
        } catch (error) {
            setUsers([]);
            setSelectedUser(null);
            setIsValidID(false);
        }
    }

    useEffect(() => {
        // get username from user id
        getUsernameFromPhoneNumber(userPhoneNumber)
    }, [userPhoneNumber]);

    const showPhoneNumberInputField = (
        <TextField
            label="Phone number"
            value={userPhoneNumber}
            type="number"
            onChange={(e) => setUserPhoneNumber(e.target.value)}
        />);

    const showUsers = (<List style={{ maxHeight: '200px', overflow: 'auto' }}>
        {users.map((user) => (


            <ListItemButton
                key={user.id}
                selected={selectedUser === user}
                onClick={() => setSelectedUser(user)}
            >
                <ListItemText primary={user.name} />
            </ListItemButton>

        ))} </List>);

    const showSelectedUser = selectedUser ? (<p>{selectedUser.name} {selectedUser.phone_number}</p>) : (<p>No User Selected</p>);
    const body = (
        <Box sx={{ minWidth: 120 }}>
            {showPhoneNumberInputField}
            {showUsers}
            {showSelectedUser}
            {showPlans}
            {showPlanName}
            {showDurations}
            {showDishCategories}
        </Box>
    );
    return <Layout title="Buy Subscription" body={body} />; // Add a closing tag for the Layout element
}

export default BuySubscription;