import { Box, Paper, Stack, TextField, Typography } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import LoadingButton from "@mui/lab/LoadingButton";
import logo from "../static/images/scale logo.svg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axiosInstance from "../api/axios";
function LoginPage() {
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (username !== "" && password !== "") {
            try {
                setLoading(true);
                const response = await axiosInstance.post(
                    "/login/",
                    JSON.stringify({ username, password })
                );
                console.log(response);
                setLoading(false);
                console.log(response.data.auth_token);
                // Storing token received from login response
                sessionStorage.setItem("token", "Token " + response.data.auth_token);
                sessionStorage.setItem("isAuthenticated", "true");
                navigate("dashboard");
            } catch (error) {
                setLoading(false);
                console.log(error);
            }
        }
    };

    //write a regex to check if the email is valid
    const validateEmail = (email) => {
        const re =
            // eslint-disable-next-line no-useless-escape
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };
    return (
        <Box bgcolor="background.default">
            <Paper
                sx={{
                    mx: "auto",
                    mt: 10,
                    borderRadius: 2,
                    p: 2,
                    width: 300,
                }}
                elevation={2}
            >
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <img alt="Scale Kuwait" src={logo} height="60" />
                    <Typography variant="h4">Scale Admin</Typography>
                </Stack>
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <TextField
                        onChange={(e) => setUsername(e.target.value)}
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        required
                        sx={{ width: "100%", mt: 2 }}
                        error={!(validateEmail(username) || username === "")}
                    />
                    <TextField
                        onChange={(e) => setPassword(e.target.value)}
                        id="outlined-password-input"
                        label="Password"
                        type="password"
                        variant="outlined"
                        required
                        sx={{ width: "100%", mt: 2 }}
                    />
                    <LoadingButton
                        // onClick={onClick}
                        loading={loading}
                        loadingPosition="end"
                        variant="contained"
                        color="primary"
                        type="submit"
                        endIcon={<LoginIcon />}
                        sx={{ width: "100%", mt: 2, mr: 4 }}
                    >
                        Send
                    </LoadingButton>
                </form>
            </Paper>
        </Box>
    );
}

export default LoginPage;
